<template>
	<div class="HSMask" style="z-index: 20;">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">记账支付</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close" />
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="settlementBox">
				<div class="line">
					<div class="item">
						<div class="label">现金：</div>
						<div class="value">
							<el-input v-model="xianjin" @focus="focusXianjin" @input="inputXianjin" placeholder="0.00"></el-input>
						</div>
					</div>
					<div class="item" v-if="type === 'recharge' || ($public.isNull(userId))"></div>
					<div class="item" v-else>
						<div class="label">余额：</div>
						<div class="value">
							<el-input v-model="yue" @focus="focusYue" @input="inputYue" placeholder="0.00"></el-input>
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">微信：</div>
						<div class="value">
							<el-input v-model="weixin" @focus="focusWeixin" @input="inputWeixin" placeholder="0.00"></el-input>
						</div>
					</div>
					<div class="item">
						<div class="label">支付宝：</div>
						<div class="value">
							<el-input v-model="zhifubao" @focus="focusZhifubao" @input="inputZhifubao" placeholder="0.00"></el-input>
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">pos：</div>
						<div class="value">
							<el-input v-model="pos" @focus="focusPos" @input="inputPos" placeholder="0.00"></el-input>
						</div>
					</div>
					<div class="item">
						<div class="label">第三方：</div>
						<div class="value">
							<el-input v-model="disanfang" @focus="focusDisanfang" @input="inputDisanfang"
								placeholder="0.00"></el-input>
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">账单总价：</div>
						<div class="value">
							<el-input :value="order.totalMoney" disabled></el-input>
						</div>
					</div>
					<div class="item">
						<div class="label">折扣优惠：</div>
						<div class="value">
							<el-input :value="$public.jian(order.totalMoney, order.needMoney)" disabled></el-input>
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">应付金额：</div>
						<div class="value">
							<el-input :value="order.needMoney" disabled></el-input>
						</div>
					</div>
					<div class="item">
						<div class="label">实收金额：</div>
						<div class="value">
							<el-input v-model="shishou" @focus="focusShishou" @input="inputShishou" placeholder="0.00"></el-input>
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">找零：</div>
						<div class="value">
							<el-input v-model="zhaoling" disabled></el-input>
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">备注：</div>
						<div class="value" style="width:400px;">
							<el-input v-model="remark" placeholder="请输入结算备注"></el-input>
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item" style="padding-top:21px;">
						<div class="label" style="width:50px;"></div>
						<div class="value" style="width:300px;">
							<el-checkbox v-model="checked1" @change="changeChecked1">抹零</el-checkbox>
							<el-checkbox v-model="checked2" @change="changeChecked">打印小票</el-checkbox>
							<!-- <el-checkbox v-model="checked3">发送短信</el-checkbox> -->
						</div>
					</div>
				</div>

			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
import printJs from "../util/print.js"
export default {
	data() {
		return {
			userInfo: {
				basicInfo: {},
				cardInfo: {},
				companyInfo: {},
				status: {},
				vipGrades: {},
				pets_info: [],
			},

			// 0:现金 1:支付宝 2.微信 3:pos 4余额 5:增额 6:积分 7:押金 8:第三方 9:优惠 10:京东聚合
			xianjin: '',
			yue: '',
			weixin: '',
			zhifubao: '',
			pos: '',
			disanfang: '',

			shishou: '',
			remark: '',
			checked1: false,
			checked2: true,
			checked3: false,

			order: {
				totalMoney: 0,
				needMoney: 0,
			},
		}
	},
	computed: {
		allMoney() {
			let a = this.$public.toDoubleFixed2(
				Number(this.xianjin || 0)
				+ Number(this.yue || 0)
				+ Number(this.weixin || 0)
				+ Number(this.zhifubao || 0)
				+ Number(this.pos || 0)
				+ Number(this.disanfang || 0)
			);
			return a;
		},
		zhaoling() {
			if (this.allMoney > this.shishou) {
				let z = this.$public.jian(this.allMoney, this.shishou)
				if (Number(this.xianjin) === 0) {
					return 0
				} else {
					if (z > Number(this.xianjin)) {
						// this.$message({
						// 	message: '找零金额不能大于现金金额',
						// 	type: 'error'
						// })
						return Number(this.xianjin)
					} else {
						return z
					}
				}
			} else {
				return 0;
			}
		},
		needPay() {
			if (this.$public.jian(this.shishou, this.allMoney) > 0) {
				return this.$public.jian(this.shishou, this.allMoney);
			} else {
				return ''
			}
		}
	},
	props: {
		type: {
			default: ''
		},
		userId: {
			default: ''
		},
		params: {
			default: () => {
				return {}
			}
		},
	},
	mounted() {
		console.log(this.params,'paramsparamsparamsparamsparamsparamsparams');
		if (this.type === 'recharge') {
			this.order.totalMoney = this.params.money
			this.order.needMoney = this.order.totalMoney
			this.shishou = this.order.needMoney
		} else if (this.type === 'order') {
			this.order.totalMoney = this.params.money.total
			this.order.needMoney = this.params.money.need
			this.shishou = this.order.needMoney
		} else if (this.type === 'return') {
			this.order.totalMoney = this.params.totalPrice
			this.order.needMoney = this.params.totalPrice
			this.shishou = this.order.needMoney
		} else if (this.type === 'change') {
			this.order.totalMoney = this.params.orderPrice
			this.order.needMoney = this.params.totalPrice
			this.shishou = this.order.needMoney
			let payList = this.params.payInfo.payList
			// 现金 // 0:现金 1:支付宝 2.微信 3:pos 4余额 5:增额 6:积分 7:押金 8:第三方 9:优惠 10:京东聚合
			for (let i = 0; i < payList.length; i++) {
				if (payList[i].payType == 0) {
					this.xianjin = payList[i].money;
				} else if (payList[i].payType == 1) {
					this.zhifubao = payList[i].money;
				} if (payList[i].payType == 2) {
					this.weixin = payList[i].money;
				} else if (payList[i].payType == 4) {
					this.yue = payList[i].money;
				} else if (payList[i].payType == 3) {
					this.pos = payList[i].money;
				} else if (payList[i].payType == 8) {
					this.disanfang = payList[i].money;
				}
			}
		}
		if (this.$public.isNull(this.userId)) {
			console.log('散客支付')
		} else {
			this.getUserInfo()
		}

	},
	methods: {
		// 关闭
		close() {
			this.$emit("submit", { type: "close", data: "" })
		},
		// 提交
		submit() {
			// if(this.allMoney==0){
			// 	this.$message({
			// 		message: '请输入金额',  
			// 		type: 'error'
			// 	});
			// 	return ;
			// }
			// 支付方式金额相加不等于实收
			if (this.$public.jian(this.allMoney, Number(this.zhaoling)) !== Number(this.shishou)) {
				this.$message({
					message: '支付金额相加必须等于实收金额',
					type: 'error'
				});
				return;
			}
			let pay_list = [];
			// 现金 // 0:现金 1:支付宝 2.微信 3:pos 4余额 5:增额 6:积分 7:押金 8:第三方 9:优惠 10:京东聚合
			if (this.xianjin) {
				pay_list.push({
					"payType": 0,
					"money": Number(this.xianjin)
				})
			}
			// 余额
			if (this.yue) {
				pay_list.push({
					"payType": 4,
					"money": Number(this.yue)
				})
			}
			// 微信
			if (this.weixin) {
				pay_list.push({
					"payType": 2,
					"money": Number(this.weixin)
				})
			}
			// 支付宝
			if (this.zhifubao) {
				pay_list.push({
					"payType": 1,
					"money": Number(this.zhifubao)
				})
			}
			// pos机
			if (this.pos) {
				pay_list.push({
					"payType": 3,
					"money": Number(this.pos)
				})
			}
			// 第三方
			if (this.disanfang) {
				pay_list.push({
					"payType": 8,
					"money": Number(this.disanfang)
				})
			}
			if (this.type == "recharge") { // 创建充值订单
				let params = {
					userInfo: {
						userId: this.userInfo.id,
						userName: this.userInfo.basicInfo.nickName,
						userMobile: this.userInfo.basicInfo.userMobile,
					},
					remark: this.params.remark,
					totalPrice: Number(this.shishou),  // 实收
					orderPrice: Number(this.params.money),  // 应付
					giveMoney: Number(this.params.moneyZeng),
					payInfo: {
						"payRemark": this.remark,
						"payList": pay_list  //支付方式 -1 全部 0 现金 3 线下支付宝 4 线下微信 5 pos机 6 找零 7 余额 8 赠额 9 积分
					},
					salesList: this.params.salesList,
				}
				let loading = this.$loading()
				this.$http.post('/business/RechargeOrder/Create', params).then(res => {
					loading.close();
					if (res.code == 0) {
						this.$emit("submit", { type: 'success' })
						if (this.checked2 == true) {
							printJs.printTicketRechargeOrder(res.data).then(res => {
								this.$store.commit("set_printUrl", res)
								var hostObject = window.chrome.webview.hostObjects.customWebView2HostObject;
								hostObject.print(res);


							})
						}

					}
				})
			} else if (this.type === "order") {
				if (Number(this.shishou) == 0) {
					pay_list.push({
						"payType": 0,
						"money": Number(this.xianjin)
					})
				}
				let payInfo = {
					"payRemark": this.remark,
					"payList": pay_list,  //支付方式 -1 全部 0 现金 3 线下支付宝 4 线下微信 5 pos机 6 找零 7 余额 8 赠额 9 积分
				}

				let params = this.params.params
				params.payInfo = payInfo
				params.totalPrice = Number(this.shishou)
				this.$http.post("/business/Order/CreatePaid", params).then(res => {
					if (res.code === 0) {
						this.$emit("submit", { type: 'success', data: res.data })
						if (this.checked2 == true) {
							printJs.printTicketSaleOrder(res.data).then(res => {
								this.$store.commit("set_printUrl", res)
								var hostObject = window.chrome.webview.hostObjects.customWebView2HostObject;
								hostObject.print(res);

							})
						}
					}
				})
			} else if (this.type === "return") {
				let params = this.params
				params.payList = pay_list
				params.payRemark = this.remark
				params.totalPrice = Number(this.shishou)
				this.$http.post("/business/RefundOrder/CreatePaid", params).then(res => {
					if (res.code === 0) {
						this.$emit("submit", { type: 'success' })
					}
				})
			} else if (this.type === "change") {
				if (Number(this.shishou) == 0) {
					pay_list.push({
						"payType": 0,
						"money": Number(this.xianjin)
					})
				}
				let payInfo = {
					"payRemark": this.remark,
					"payList": pay_list  //支付方式 -1 全部 0 现金 3 线下支付宝 4 线下微信 5 pos机 6 找零 7 余额 8 赠额 9 积分
				}
				let params = {
					"orderNo": this.params.orderNo,
					"payInfo": payInfo,
					"totalPrice": Number(this.shishou),
				}
				this.$http.post("/order/OrderConsume/UpdatePayInfo", params).then(res => {
					if (res.code === 0) {
						this.$emit("submit", { type: 'success' })
					}
				})
			}
		},
		// 获取用户信息
		getUserInfo() {
			if (this.$public.isNull(this.userId)) {
				return;
			}
			let params = {
				id: this.userId,
			}
			this.$http.get('/customer/Member/Get', params).then(res => {
				if (res.code === 0) {
					this.userInfo = res.data;
				}
			})
		},
		// 聚焦
		focusXianjin() {
			this.xianjin = ''
			if (this.$public.isNull(this.xianjin)) {
				this.checked1 = false
				this.xianjin = this.needPay;
			}
		},
		inputXianjin() {
			this.checked1 = false
			this.xianjin = this.$public.replaceToDouble(this.xianjin)
		},
		focusYue() {
			this.yue = ''
			if (this.$public.isNull(this.yue)) {
				this.checked1 = false
				if (this.userInfo.cardInfo.userYue < this.needPay) {
					this.yue = this.userInfo.cardInfo.userYue;
				} else {
					this.yue = this.needPay;
				}
			}
		},
		inputYue() {
			this.checked1 = false
			this.yue = this.$public.replaceToDouble(this.yue)
		},
		focusWeixin() {
			this.weixin = ''
			if (this.$public.isNull(this.weixin)) {
				this.checked1 = false
				this.weixin = this.needPay;
			}
		},
		inputWeixin() {
			this.checked1 = false
			this.weixin = this.$public.replaceToDouble(this.weixin)
		},
		focusZhifubao() {
			this.zhifubao = ''
			if (this.$public.isNull(this.zhifubao)) {
				this.checked1 = false
				this.zhifubao = this.needPay;
			}
		},
		inputZhifubao() {
			this.checked1 = false
			this.zhifubao = this.$public.replaceToDouble(this.zhifubao)
		},
		focusPos() {
			this.pos = ''
			if (this.$public.isNull(this.pos)) {
				this.checked1 = false
				this.pos = this.needPay;
			}
		},
		inputPos() {
			this.checked1 = false
			this.pos = this.$public.replaceToDouble(this.pos)
		},
		focusDisanfang() {
			this.disanfang = ''
			if (this.$public.isNull(this.disanfang)) {
				this.checked1 = false
				this.disanfang = this.needPay;
			}
		},
		inputDisanfang() {
			this.checked1 = false
			this.disanfang = this.$public.replaceToDouble(this.disanfang)
		},
		focusShishou() {
			if (this.allMoney > Number(this.shishou)) {
				this.checked1 = false
				this.shishou = this.allMoney;
			}
		},
		inputShishou() {
			this.checked1 = false
			this.shishou = this.$public.replaceToDouble(this.shishou)
		},
		// 抹零
		changeChecked1(b) {
			if (b === true) {
				this.shishou = Math.floor(this.shishou)
			}
		},
		// 打印小票
		changeChecked(b) {
			if (b === false) {
				this.checked2 = false
			} else {
				this.checked2 = true
			}
		},
	}
};
</script>
<style lang="scss" scoped>
.settlementBox {
	width: 540px;

	.line {
		border-bottom: 1px solid #F2F2F2;
		height: 64px;
		padding: 0 20px;

		.item {
			display: inline-block;
			padding-top: 12px;

			.label {
				font-size: 16px;
				color: $fontColor;
				display: inline-block;
				width: 100px;
				text-align: right;
			}

			.value {
				width: 150px;
				display: inline-block;
			}
		}
	}
}
</style>
